import { CSSProperties } from "react";
import Chelsea from "../../assets/images/icon/chelsea.png";
import ManU from "../../assets/images/icon/man-utd.png";

interface Props {
  style?: CSSProperties;
}

export const CurrentCard: React.FC<Props> = ({ style }) => {
  return (
    <div
      className="hero_area__topslider-card swiper-slide p-4 p-md-6"
      style={style}
    >
      <div className="hero_area__topslider-cardtop text-center mb-4 mb-md-6">
        <span className="fs-ten d-block mb-1">Stamford Bridge</span>
        <span className="n3-color cpoint">Week 10</span>
      </div>
      <div className="hero_area__topslider-cardbody d-flex align-items-center justify-content-between mb-4 mb-md-6">
        <div className="hero_area__topslider-flag d-center flex-column">
          <img className="mb-2" src={Chelsea} alt="Icon" />
          <span className="cpoint fs-seven mb-1">Chelsea</span>
          <span className="n3-color fs-eight">Away</span>
        </div>
        <div className="hero_area__topslider-scr">
          <h3 className="mb-2">1 : 1</h3>
          <span className="fs-seven py-1 px-2 rounded-5 cpoint">90+4</span>
        </div>
        <div className="hero_area__topslider-flag text-end d-flex flex-column justify-content-center align-items-center ">
          <img className="mb-2" src={ManU} alt="Icon" />
          <span className="cpoint fs-seven mb-1">Man Utd</span>
          <span className="n3-color fs-eight">Away</span>
        </div>
      </div>
    </div>
  );
};
