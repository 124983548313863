import { useParams } from "react-router-dom";
import { Header } from "../components/layouts/Header";
import { Footer } from "../components/layouts/Footer";

import { TopMatchSection } from "../components/sports/TopMatchSection";
import { UpcomingEvents } from "../components/sports/UpcomingEvents";

export const Sport = () => {
  const { id } = useParams();

  return (
    <>
      <Header />

      <TopMatchSection text={id} />

      {id !== "crypto" && <UpcomingEvents text={id} />}

      <Footer />
    </>
  );
};
