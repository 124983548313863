import { useNavigate } from "react-router-dom";

interface Props {
  team1?: string;
  team2?: string;
  onClose: () => void;
}

export const BetModal: React.FC<Props> = ({
  onClose,
  team1 = "Thailand",
  team2 = "Australia",
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="p-5"
      style={{
        position: "absolute",
        borderRadius: 20,
        alignSelf: "center",
        zIndex: 999,
        right: 10,
        background: "white",
        color: "black",
        width: "50%",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h5 style={{ color: "black" }}>{team1}</h5>
          <p style={{ color: "black" }}>
            {team1} vs {team2}
          </p>
        </div>

        <i
          className="ti ti-circle-x"
          style={{ fontSize: 30, cursor: "pointer" }}
          onClick={onClose}
        ></i>
      </div>

      <label style={{ color: "black", marginTop: 20 }}>Total Stake:</label>
      <input
        type="number"
        className="form-control"
        placeholder="Stake amount"
        style={{
          //   background: "white",
          //   border: "1px solid gray",
          //   borderRadius: 10,
          // color: "black",
          marginTop: 5,
        }}
      />

      <button
        onClick={() => navigate("/p2p")}
        className="bet-btn px-5 py-3 mb-6 w-100 mt-5"
      >
        Place Bet
      </button>
    </div>
  );
};
