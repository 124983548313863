import { AltHeader } from "../components/layouts/AltHeader";
import Book from "../assets/images/book2.png";
import Shape from "../assets/images/shape.png";

import { auth } from "../config/firebase.config";

import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Claim = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const SignInWithTwitter = () => {
    setLoading(true);

    const provider = new TwitterAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {
        let url = "https://app.theumpire.io/auth/store";

        axios
          .post(
            url,
            {
              name: res.user.displayName,
              email: res.user.email ? res.user.email : "",
            },
            { headers: { "X-Requested-With": "XMLHttpRequest" } }
          )
          .then((response) => {
            setLoading(false);
            localStorage.setItem(
              "authuserDetails",
              JSON.stringify({
                name: res.user.displayName,
                email: res.user.email,
              })
            );
            navigate("/");
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <AltHeader />

      <div
        className="road-map pt-100 pb-100"
        style={{ paddingTop: 200, background: "#0B0B47" }}
      >
        <div className="container">
          <div className="row px-100">
            <div className="col-lg-12">
              <div className="dreamit-section-title text-center pb-20">
                <div className="dreamit-section-sub-title">
                  <h5>CLAIM $UMPIRE AIRDROPs</h5>
                </div>
                <div className="dreamit-section-main-title">
                  <h1>IN FEW EASY STEPS</h1>
                </div>
              </div>
            </div>
          </div>

          <div id="roadmap-container" className="roadmap-container">
            <div className="roadmap-auto">
              <div className="roadmap-timeline">
                <div className="timeline-dot">&nbsp;</div>
                <div className="timeline-list">
                  <div className="timeline-list-vr">&nbsp;</div>
                  <div className="timeline-list-col timeline-col-left">
                    <div className="timeline-item-block timeline-item-block-green">
                      <div className="timeline-item-label-hr">
                        <div className="item-dot timeline-dot">&nbsp;</div>
                      </div>

                      <div className="single-road-map-box">
                        <div className="road-map-content">
                          <h3>STEP ONE</h3>
                          <div className="road-map-list">
                            <p style={{ paddingBottom: 20 }}>Connect to X</p>
                            <div
                              className="btn btn-default"
                              onClick={SignInWithTwitter}
                              style={{ background: "black" }}
                            >
                              {loading ? "Connecting..." : "Connect"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="timeline-item-block timeline-item-block-mobile timeline-item-block-blue">
                      <div className="timeline-item-label-hr">
                        <div className="item-dot timeline-dot">&nbsp;</div>
                      </div>
                      <div className="single-road-map-box">
                        <div className="road-map-content">
                          <h3>STEP TWO</h3>
                          <div className="road-map-list">
                            <p style={{ paddingBottom: 20 }}>Follow us on X</p>
                            <a
                              href="https://twitter.com/umpire268478"
                              className="btn btn-default"
                              style={{ background: "black" }}
                            >
                              Follow
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="timeline-list-col timeline-col-right">
                    <div className="timeline-item-block timeline-item-block-right timeline-item-block-violet">
                      <div className="timeline-item-label-hr">
                        <div className="item-dot timeline-dot">&nbsp;</div>
                      </div>
                      <div className="single-road-map-box">
                        <div className="road-map-content">
                          <h3>STEP TWO</h3>
                          <div className="road-map-list">
                            <p style={{ paddingBottom: 20 }}>Follow us on X</p>
                            <a
                              href="https://twitter.com/umpire268478"
                              className="btn btn-default"
                              style={{ background: "black" }}
                            >
                              Follow
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row topper2 pt-100">
            <div className="col-lg-12">
              <div className="road-map-btn text-center">
                <a
                  href="http://bet.theumpire.io"
                  style={{ background: "black" }}
                >
                  VISIT DEMO
                </a>
              </div>
            </div>
          </div>
          <div
            className="row upper5 align-items-center mt-90"
            style={{ marginTop: 90 }}
          >
            <div className="col-lg-6 col-md-12">
              <div className="dreamit-section-title pb-20">
                <div className="dreamit-section-sub-title">
                  <h5>REASON TO CHOOSE US</h5>
                </div>
                <div className="dreamit-section-main-title">
                  <h1>Why Choose $UMPIRE?</h1>
                </div>
                <div className="dreamit-section-content-text">
                  <p>
                    UMPIRE is the first Crypto and Sports Betting Arbitrator
                    token that ensures fairness and transparency in the betting
                    Arena.
                  </p>
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-lg-6 col-md-6">
                  <div className="single-road-icon-box d-flex align-items-center">
                    <div className="road-icon">
                      <i className="flaticon-checked"></i>
                    </div>
                    <div className="icon-content">
                      <h4>LP Locked for 25 Years</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-road-icon-box d-flex align-items-center">
                    <div className="road-icon two">
                      <i className="flaticon-checked"></i>
                    </div>
                    <div className="icon-content">
                      <h4>0% Taxes / Team Allocation</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-road-icon-box d-flex align-items-center">
                    <div className="road-icon three">
                      <i className="flaticon-checked"></i>
                    </div>
                    <div className="icon-content">
                      <h4>Anti-BOT Mechanism</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-road-icon-box d-flex align-items-center">
                    <div className="road-icon four">
                      <i className="flaticon-checked"></i>
                    </div>
                    <div className="icon-content">
                      <h4>Contract Renounced Post Launch</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="road-icon-btn">
                <a href="#">
                  Whitepaper (Coming Soon)
                  <i className="flaticon-angle-arrow-down"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="road-thumb bounce-animate4">
                <img src={Book} alt="" />
                <div className="shape7 bounce-animate2">
                  <img src={Shape} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="shape6">
            <img src="assets/images/line2.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
