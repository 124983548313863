import IceHockey from "../../assets/images/icon/ice-hockey.png";

import Club1 from "../../assets/images/icon/queensland.png";
import Club2 from "../../assets/images/icon/western-australia.png";
import Chart from "../../assets/images/icon/line-chart.png";
import Star from "../../assets/images/icon/star2.png";

export const HockeyCard = () => {
  return (
    <div className="top_matches__cmncard p2-bg p-4 rounded-3 mb-4">
      <div className="row gx-0 gy-xl-0 gy-7">
        <div className="col-xl-5 col-xxl-4">
          <div className="top_matches__clubname">
            <div className="top_matches__cmncard-right d-flex align-items-start justify-content-between pb-4 mb-4 gap-4 ">
              <div className="d-flex align-items-center gap-1">
                <img src={IceHockey} alt="Icon" />{" "}
                <span className="fs-eight cpoint">Australia WNBL</span>
              </div>
              <div className="d-flex align-items-center gap-4 pe-xl-15 flex-nowrap flex-xl-wrap">
                <span className="fs-eight cpoint me-6">Today 13:30</span>
              </div>
            </div>
            <div className="top_matches__cmncard-left d-flex align-items-center justify-content-between pe-xl-10">
              <div>
                <div className="d-flex align-items-center gap-2 mb-4">
                  <img src={Club1} alt="Icon" />{" "}
                  <span className="fs-seven cpoint">Townsville Fire</span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img src={Club2} alt="Icon" />{" "}
                  <span className="fs-seven cpoint">Adelaide Lightning</span>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 position-relative pe-xl-15">
                <span className="v-line lg d-none d-xl-block mb-15"></span>
                <div className="d-flex flex-column gap-5">
                  <img className="cpoint" src={Chart} alt="Icon" />
                  <img
                    className="cpoint visually-hidden"
                    src={Star}
                    alt="Icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-xxl-8">
          <div className="top_matches__clubdata">
            <div className="table-responsive">
              <table className="table mb-0 pb-0">
                <thead>
                  <tr className="text-center">
                    <th scope="col">
                      <span className="fs-eight">Winner (incl. overtime)</span>
                    </th>
                    <th scope="col">
                      <span className="fs-eight">
                        Handicap (incl. overtime)
                      </span>
                    </th>
                    <th scope="col">
                      <span className="fs-eight">Total (incl overtime)</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pt-4">
                      <div className="top_matches__innercount d-flex align-items-center gap-2 ">
                        <div className="top_matches__innercount-item clickable-active py-1 px-7 rounded-3 n11-bg text-center">
                          <span className="fs-seven d-block mb-2 text-nowrap">
                            1
                          </span>
                          <span className="fw-bold d-block text-nowrap">
                            1.39
                          </span>
                        </div>
                        <div className="top_matches__innercount-item clickable-active py-1 px-7 rounded-3 n11-bg text-center">
                          <span className="fs-seven d-block mb-2 text-nowrap">
                            2
                          </span>
                          <span className="fw-bold d-block text-nowrap">
                            3.45
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="pt-4">
                      <div className="top_matches__innercount d-flex align-items-center gap-2 ">
                        <div className="top_matches__innercount-item clickable-active py-1 px-7 rounded-3 n11-bg text-center">
                          <span className="fs-seven d-block mb-2 text-nowrap">
                            (7.5) 1
                          </span>
                          <span className="fw-bold d-block text-nowrap">
                            1.39
                          </span>
                        </div>
                        <div className="top_matches__innercount-item clickable-active py-1 px-7 rounded-3 n11-bg text-center">
                          <span className="fs-seven d-block mb-2 text-nowrap">
                            (-7.5) 1
                          </span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                        <div className="top_matches__innercount-item clickable-active py-1 px-7 rounded-3 n11-bg text-center">
                          <span className="fs-seven d-block mb-2 text-nowrap">
                            (50) 1
                          </span>
                          <span className="fw-bold d-block">2.28</span>
                        </div>
                        <div className="top_matches__innercount-item clickable-active py-1 px-7 rounded-3 n11-bg text-center">
                          <span className="fs-seven d-block mb-2 text-nowrap">
                            (-7.5) 1
                          </span>
                          <span className="fw-bold d-block">2.28</span>
                        </div>
                      </div>
                    </td>
                    <td className="pt-4">
                      <div className="top_matches__innercount d-flex align-items-center gap-2 ">
                        <div className="top_matches__innercount-item clickable-active py-1 px-7 rounded-3 n11-bg text-center">
                          <span className="fs-seven d-block mb-2 text-nowrap">
                            (-1.5) 1
                          </span>
                          <span className="fw-bold d-block text-nowrap">
                            2.28
                          </span>
                        </div>
                        <div className="top_matches__innercount-item clickable-active py-1 px-7 rounded-3 n11-bg text-center">
                          <span className="fs-seven d-block mb-2 text-nowrap">
                            (5) 1
                          </span>
                          <span className="fw-bold d-block text-nowrap">
                            2.28
                          </span>
                        </div>
                        <div className="top_matches__innercount-item clickable-active py-1 px-7 rounded-3 n11-bg text-center">
                          <span className="fs-seven d-block mb-2 text-nowrap">
                            over 3.0
                          </span>
                          <span className="fw-bold d-block text-nowrap">
                            3.45
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
