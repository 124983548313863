import Chelsea from "../../assets/images/icon/chealse.png";
import Liverpool from "../../assets/images/icon/liverpool.png";

export const HeroCard = () => {
  return (
    <div className="hero_area__topslider-card swiper-slide p-4 p-md-6">
      <div className="hero_area__topslider-cardtop d-flex align-items-center justify-content-between mb-4 mb-md-6">
        <div className="d-flex align-items-center gap-2">
          <i className="fa fa-star n5-color" style={{ color: "white" }}></i>
          <span className="fs-seven n5-color cpoint">Premier league</span>
        </div>
        <span className="fs-seven n5-color cpoint">Feb 2, 00:00</span>
      </div>
      <div className="hero_area__topslider-cardbody d-flex align-items-center justify-content-between mb-4 mb-md-6">
        <div className="hero_area__topslider-flag">
          <div className="hero_area__topslider-flagbox mb-2">
            <img src={Chelsea} alt="icon" />
          </div>
          <h6 className="cpoint">Chelsea</h6>
        </div>
        <div className="hero_area__topslider-vs">
          <span className="fw-bold n5-color">VS</span>
        </div>
        <div className="hero_area__topslider-flag text-end d-flex flex-column justify-content-end align-items-end ">
          <div className="hero_area__topslider-flagbox mb-2">
            <img src={Liverpool} alt="icon" />
          </div>
          <h6 className="cpoint">Liverpool</h6>
        </div>
      </div>
      <div className="hero_area__topslider-cardfooter d-flex align-items-center justify-content-between gap-4">
        <div className="hero_area__topslider-cfitem d-flex align-items-center gap-4 py-2 justify-content-center w-100 p2-bg cpoint">
          <span className="fs-eight n5-color">1</span>
          <span className="fw-bold fs-eight">1.87</span>
        </div>
        <div className="hero_area__topslider-cfitem d-flex align-items-center gap-4 py-2 justify-content-center w-100 p2-bg cpoint">
          <span className="fs-eight n5-color">X</span>
          <span className="fw-bold fs-eight">1.87</span>
        </div>
        <div className="hero_area__topslider-cfitem d-flex align-items-center gap-4 py-2 justify-content-center w-100 p2-bg cpoint">
          <span className="fs-eight n5-color">2</span>
          <span className="fw-bold fs-eight">1.87</span>
        </div>
      </div>
    </div>
  );
};
