import { Footer } from "../components/layouts/Footer";
import { Header } from "../components/layouts/Header";

export const Wallet = () => {
  return (
    <>
      <Header />

      <section className="top_matches pb-8 pb-md-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 gx-0 gx-sm-4">
              <div className="top_matches__main pt-20">
                <div className="row w-100 mb-7 mb-md-8 pt-md-5">
                  <div className="col-12 pe-0">
                    <div className="top_matches__title d-flex align-items-center gap-sm-2 mb-4 mb-md-5">
                      <i className="ti ti-calendar-event fs-three"></i>
                      <h3>My Wallet</h3>
                    </div>
                    <div className="top_matches__content">
                      <div className="top_matches__winnercard p-4 p-sm-5 p-md-6 rounded-3 p2-bg">
                        <div className="top_matches__winnercard-title d-flex align-items-center gap-2 mb-5 mb-md-6">
                          <div className="top_matches__winnercard-obi">
                            {/* <span className="mb-3 n4-color">
                              Jan 1, 2024, 10:00
                            </span> */}
                            <span
                              className="mb-3 n4-color"
                              style={{ width: "80%" }}
                            >
                              Umpire supports betting in Umpire Tokens only.
                              Umpire Tokens are cryptocurrencies pegged 1:1 to
                              the U.S. dollar, meaning 1 Ump = $1.00.
                            </span>
                            <br />
                            <span
                              className="mb-3 n4-color"
                              style={{ width: "80%" }}
                            >
                              The Umpire Token (wrapped version - ump) can be
                              staked to earn daily staking rewards, reduce
                              betting fees and participate in governance.
                            </span>
                          </div>
                        </div>
                        <div className="row gy-2 mb-3">
                          <div className="col-sm-6 col-xl-4">
                            <h5>Asset</h5>
                          </div>
                          <div className="col-sm-6 col-xl-4">
                            <h5>Amount</h5>
                          </div>
                          <div className="col-sm-6 col-xl-4"></div>
                        </div>

                        <div className="row gy-2">
                          <div className="col-sm-6 col-xl-4">
                            <div className="top_matches__winnercard-sctn w-100">
                              <div className="top_matches__winnercard-singe d-flex align-items-center justify-content-between n11-bg rounded-2 py-2 px-3 mb-2">
                                <span>Umpire Token</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4">
                            <div className="top_matches__winnercard-sctn w-100">
                              <div className="top_matches__winnercard-singe d-flex align-items-center justify-content-between n11-bg rounded-2 py-2 px-3 mb-2">
                                <span>$1,000</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4">
                            <div className="top_matches__winnercard-sctn w-100">
                              <div className="top_matches__winnercard-singe d-flex align-items-center justify-content-between n11-bg rounded-2 py-2 px-3">
                                <span>Buy now</span>
                                <i className="ti ti-arrow-right cpoint mt-1"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-12" style={{ marginTop: 40 }}>
                            <div
                              style={{ position: "relative" }}
                              className="top_matches__winnercard-singe d-flex align-items-center justify-content-between n11-bg rounded-2 py-3 px-3 mb-2 gap-2 mt-2"
                            >
                              <p
                                className="cpoint"
                                style={{
                                  position: "absolute",
                                  fontSize: 11,
                                  top: -10,
                                }}
                              >
                                Umpire token address
                              </p>
                              <span className="cpoint">
                                0x5EaF08f68bE5e07AD0dC3ff2CA2C301C102CE8E7
                              </span>
                              <i
                                className="ti ti-copy cpoint mt-1"
                                style={{ fontSize: 25, color: "#4CA2BF" }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
