import { useEffect, useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./pages/auth/Login";
import { Register } from "./pages/auth/Register";

// import { checkUser } from "./service/magic";

import { Claim } from "./pages/Claim";
import { Home } from "./pages/Home";
import { Sport } from "./pages/Sport";
import { Wallet } from "./pages/Wallet";
import { P2P } from "./pages/P2P";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";

import {
  CloverWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter,
  TrustWalletAdapter,
  CoinbaseWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
  useEffect(() => {
    const validateUser = async () => {
      try {
        // await checkUser(setUser);
      } catch (error) {
        console.error(error);
      }
    };
    validateUser();
  }, []);

  const wallets = [
    new TrustWalletAdapter(),
    new CoinbaseWalletAdapter(),
    new PhantomWalletAdapter(),
    new CloverWalletAdapter(),
    new SolflareWalletAdapter(),
    new MathWalletAdapter(),
  ];
  const endpoint = useMemo(() => clusterApiUrl("mainnet-beta"), []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          {/* @ts-ignore */}
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />

              <Route path="/claim" element={<Claim />} />

              <Route path="/" element={<Home />} />
              <Route path="/sport/:id" element={<Sport />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/p2p" element={<P2P />} />
            </Routes>
          </BrowserRouter>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
