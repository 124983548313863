import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginImg from "../../assets/images/authImg.svg";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import { auth } from "../../config/firebase.config";
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { loginUser } from "../../service/magic";
import axios from "axios";

export const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const SignInWithTwitter = () => {
    setLoading(true);

    const provider = new TwitterAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {
        let url = "https://app.theumpire.io/auth/store";

        axios
          .post(
            url,
            {
              name: res.user.displayName,
              email: res.user.email ? res.user.email : "",
            },
            { headers: { "X-Requested-With": "XMLHttpRequest" } }
          )
          .then((response) => {
            setLoading(false);
            localStorage.setItem(
              "authuserDetails",
              JSON.stringify({
                name: res.user.displayName,
                email: res.user.email,
              })
            );
            navigate("/");
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const register = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (!email) {
      setLoading(false);
      return;
    }
    try {
      await loginUser(email);

      let url = "https://app.theumpire.io/auth/store";
      let name = email.split("@");
      axios
        .post(
          url,
          { name: name[0], email: email },
          { headers: { "X-Requested-With": "XMLHttpRequest" } }
        )
        .then((response) => {
          let data = response.data;

          setLoading(false);
          localStorage.setItem(
            "authuserDetails",
            JSON.stringify({ name: data.name, email: data.email })
          );
          navigate("/");
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section className="login_section pt-120 p3-bg">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-6 d-lg-flex d-sm-none align-items-center justify-content-center">
            <img
              src={LoginImg}
              alt="Logo"
              style={{ borderRadius: 20, height: 400, width: 400 }}
            />
          </div>
          <div className="col-lg-6 col-xl-5">
            <div className="login_section__loginarea">
              <div className="row justify-content-start">
                <div className="col-xxl-10">
                  <div className="pb-10 pt-8 mb-7 mt-12 mt-lg-0 px-4 px-sm-10">
                    <h3 className="mb-6 mb-md-8">Register</h3>
                    <p className="mb-10 mb-md-15">
                      Welcome to Umpire. Live Cryptos and Sports P2P Betting.
                    </p>
                    <div className="login_section__form">
                      <form onSubmit={register}>
                        <div className="mb-5 mb-md-6">
                          <input
                            className="n11-bg"
                            name="Input-1"
                            data-name="Input 1"
                            placeholder="Email"
                            type="email"
                            id="email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <button
                          className="cmn-btn px-5 py-3 mb-6 w-100"
                          type="submit"
                          disabled={loading ? true : false}
                        >
                          {loading ? "Completing process..." : "Register"}
                        </button>

                        <button
                          className="cmn-btn px-5 py-3 mb-6 w-100"
                          onClick={SignInWithTwitter}
                          type="button"
                          style={{
                            background: "transparent",
                            border: "1px solid #4CA2BF",
                            color: "#4CA2BF",
                          }}
                        >
                          {loading ? "Loading..." : "Connect with X"}
                        </button>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: 20,
                            marginTop: 20,
                          }}
                        >
                          <div
                            style={{
                              height: 1,
                              width: "45%",
                              background: "#535b71",
                            }}
                          />
                          <p style={{ color: "#535b71" }}>OR</p>
                          <div
                            style={{
                              height: 1,
                              width: "45%",
                              background: "#535b71",
                            }}
                          />
                        </div>

                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ width: "100%" }}
                        >
                          <WalletMultiButton className="wallet-adapter-button" />
                        </div>
                      </form>
                    </div>

                    <span className="d-center gap-1 mt-5">
                      Already have an account?{" "}
                      <Link
                        to="/login"
                        className="g1-color"
                        style={{ color: "#4CA2BF" }}
                      >
                        Login Now
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
