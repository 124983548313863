import { Header } from "../components/layouts/Header";
import { Footer } from "../components/layouts/Footer";
import { P2pCard } from "../components/utilities/P2pCard";
import { CurrentCard } from "../components/sport_card/CurrentCard";

export const P2P = () => {
  return (
    <>
      <Header />

      <section className="top_matches pb-8 pb-md-10 pt-10 mt-10">
        <div className="container-fluid pt-10 mt-10">
          <div className="col-12 gx-0 gx-lg-4">
            <div className="top_matches__main">
              <div
                className="row w-100 mb-8 mb-md-10"
                style={{
                  justifyContent: "center",
                }}
              >
                <div className="row">
                  <div className="col-12 d-flex justify-content-center align-items-center w-100 mb-1">
                    <h5>Time left</h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 d-flex justify-content-center align-items-center w-100 mb-10 pb-10">
                    <h3 className="timelapse">3 hrs : 20 minutes : 10 Secs</h3>
                  </div>
                </div>

                <div className="row d-flex justify-content-center align-items-center w-100">
                  <div className="col-lg-5 col-sm-12 gx-0 gx-lg-4 mb-10">
                    <P2pCard odds="Below 1.5" name="You" />
                  </div>

                  <div className="col-lg-2 col-sm-12 gx-0 gx-lg-4 mb-10 d-flex align-items-center justify-content-center">
                    <h5 className="vs">VS</h5>
                  </div>

                  <div className="col-lg-5 col-sm-12 gx-0 gx-lg-4 mb-10">
                    <P2pCard odds="Above 1.5" name="Ricardo Vargas" />
                  </div>
                </div>

                <div className="row mb-8 mb-md-10">
                  <div className="col-12">
                    <div className="d-flex align-items-center">
                      <CurrentCard
                        style={{
                          border: "1px solid #4ca2bf98",
                          borderRadius: 15,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
