import Logo from "../../assets/images/logoalt.svg";

export const Footer = () => {
  return (
    <footer className="footer_section pt-5 pt-md-7 pt-lg-10 p2-bg pb-7 pb-md-0">
      <div className="container-fluid">
        <div className="row mb-10 mb-md-10 mb-lg-10">
          <div className="col-12">
            <div className="footer_section__main">
              <div className="row gy-8">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                  <div className="footer_section__promotions">
                    <ul className="d-flex flex-column gap-5">
                      <li className="iconstyle d-flex align-items-center">
                        <i className="ti ti-arrow-badge-right fs-five"></i>
                        <img src={Logo} height={100} width={80} alt="Logo" />
                      </li>
                      <li className="iconstyle d-flex r">
                        <i className="ti ti-arrow-badge-right fs-five"></i>
                        <a
                          href="javascript:void(0)"
                          className="fs-ten n4-color"
                        >
                          The Crypto P2p Bookie Cat. <br />
                          UMPIRE is a unique utitility meme coin build on the
                          Binance Smart Chain(BSC).
                        </a>
                      </li>
                      <h4 className="mb-1">Join our Community</h4>
                      <li>
                        <a
                          className="footer_section__community-sitem n4-color"
                          href="javascript:void(0)"
                        >
                          <i className="ti ti-brand-x fs-three"></i>
                        </a>
                      </li>
                    </ul>

                    {/* <ul className="d-flex align-items-center just flex-wrap gap-5">
                    </ul> */}
                  </div>
                </div>
                {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2 col-xxl-2">
                  <div className="footer_section__sports">
                    <h4 className="mb-5 mb-md-6">Sports</h4>
                    <ul className="d-flex flex-column gap-5">
                      <li className="iconstyle d-flex align-items-center">
                        <i className="ti ti-arrow-badge-right fs-five"></i>
                        <a className="fs-ten n4-color" href="/sport/Crypto">
                          Crypto
                        </a>
                      </li>
                      <li className="iconstyle d-flex align-items-center">
                        <i className="ti ti-arrow-badge-right fs-five"></i>
                        <a className="fs-ten n4-color" href="/sport/Football">
                          Football
                        </a>
                      </li>
                      <li className="iconstyle d-flex align-items-center">
                        <i className="ti ti-arrow-badge-right fs-five"></i>
                        <a className="fs-ten n4-color" href="/sport/Basketball">
                          Basketball
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}

                {/* <div className="col-sm-8 col-md-5 col-lg-6 col-xxl-3">
                  <div className="footer_section__community">
                    <h4 className="mb-5 mb-md-6">Join our Community</h4>
                    <ul className="d-flex align-items-center flex-wrap gap-5">
                      <li>
                        <a
                          className="footer_section__community-sitem n4-color"
                          href="javascript:void(0)"
                        >
                          <i className="ti ti-brand-x fs-three"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
