import { Header } from "../components/layouts/Header";
import { Footer } from "../components/layouts/Footer";

import { HeroSlider } from "../components/hero/HeroSlider";
import { TopMatchSection } from "../components/home/TopMatchSection";
import { UpcomingEvents } from "../components/home/UpcomingEvents";

export const Home = () => {
  return (
    <>
      <Header />
      <HeroSlider />

      <TopMatchSection />
      <UpcomingEvents />

      <Footer />
    </>
  );
};
