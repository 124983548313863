import { BasketballCard } from "../sport_card/BasketballCard";
import { CricketCard } from "../sport_card/CricketCard";
import { ECricketCard } from "../sport_card/ECricketCard";
import { FootballCard } from "../sport_card/FootballCard";
import { HockeyCard } from "../sport_card/HockeyCard";
import { NBACard } from "../sport_card/NBACard";
import { TennisCard } from "../sport_card/TennisCard";

import Upcoming from "../../assets/images/icon/clock-icon.png";
import { CryptoCard } from "../sport_card/CryptoCard";
import { UfcCard } from "../sport_card/UfcCard";

interface Props {
  text: string | undefined;
}

export const UpcomingEvents: React.FC<Props> = ({ text }) => {
  return (
    <section className="top_matches pb-8 pb-md-10">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 gx-0 gx-lg-4">
            <div className="top_matches__main">
              <div className="row w-100 mb-8 mb-md-10">
                <div className="col-12">
                  <div className="top_matches__title d-flex align-items-center gap-2 mb-4">
                    <img src={Upcoming} alt="Icon" />
                    <h3>Upcoming events</h3>
                  </div>
                  <div className="top_matches__content">
                    <div className="singletab">
                      <div className="tabcontents">
                        <div
                          className={`tabitem ${text === "Crypto" && "active"}`}
                        >
                          <CryptoCard text="Ethereum" />
                          <CryptoCard text="Solana" />
                          <CryptoCard text="Bitcoin" />
                        </div>

                        <div
                          className={`tabitem ${
                            text === "Football" && "active"
                          }`}
                        >
                          <FootballCard />
                          <FootballCard />
                          <FootballCard />
                        </div>

                        <div
                          className={`tabitem ${text === "Ufc" && "active"}`}
                        >
                          <UfcCard />
                          <UfcCard />
                          <UfcCard />
                        </div>

                        <div
                          className={`tabitem ${text === "Tennis" && "active"}`}
                        >
                          <TennisCard />
                          <TennisCard />
                        </div>

                        <div
                          className={`tabitem ${
                            text === "Basketball" && "active"
                          }`}
                        >
                          <BasketballCard />
                          <BasketballCard />
                          <BasketballCard />
                        </div>

                        <div
                          className={`tabitem ${
                            text === "Cricket" && "active"
                          }`}
                        >
                          <CricketCard />
                          <CricketCard />
                          <CricketCard />
                        </div>

                        <div
                          className={`tabitem ${
                            text === "Ecricket" && "active"
                          }`}
                        >
                          <ECricketCard />
                          <ECricketCard />
                          <ECricketCard />
                        </div>

                        <div
                          className={`tabitem ${
                            text === "Icehockey" && "active"
                          }`}
                        >
                          <HockeyCard />
                          <HockeyCard />
                          <HockeyCard />
                        </div>

                        <div
                          className={`tabitem ${text === "Nba" && "active"}`}
                        >
                          <NBACard />
                          <NBACard />
                        </div>

                        <div
                          className={`tabitem ${text === "Futsal" && "active"}`}
                        >
                          <FootballCard />
                          <FootballCard />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
