import { useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";

interface Props {
  name: string;
  odds: string;
}

export const P2pCard: React.FC<Props> = ({ name, odds }) => {
  const avatar = useMemo(() => {
    return createAvatar(identicon, {
      size: 128,
    }).toDataUriSync();
  }, []);

  return (
    <div className="pcontainer">
      <div className="pimgcontainer">
        <img src={avatar} className="pimage" alt="Profile" />
      </div>

      <h5 className="pheader">{name}</h5>
      <h6 className="psubtitle">
        Odds: <span style={{ color: "#62cdf1" }}>{odds}</span>
      </h6>
      <h6 className="psubtitle">Stake: $200</h6>
    </div>
  );
};
