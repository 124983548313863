// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC_AkyBZyGqlVNqbCEciHCkr-at5uGmhVQ",
  authDomain: "umpire-4ea48.firebaseapp.com",
  projectId: "umpire-4ea48",
  storageBucket: "umpire-4ea48.appspot.com",
  messagingSenderId: "516821273049",
  appId: "1:516821273049:web:4eff65412915453e6731c8",
  measurementId: "G-KB4571JBKL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
