import Football from "../../assets/images/icon/floorball.png";
import Updown from "../../assets/images/icon/updwon.png";
import Shirt from "../../assets/images/icon/t-shart.png";

import Club1 from "../../assets/images/icon/istanbul-basaksehir.png";
import Club2 from "../../assets/images/icon/pendikspor.png";
import Chart from "../../assets/images/icon/line-chart.png";
import Star from "../../assets/images/icon/star2.png";
import { BetModal } from "../modals/BetModal";
import { useState } from "react";

export const FootballCard = () => {
  const [showModal, setshowModal] = useState(false);
  return (
    <div
      className="top_matches__cmncard p2-bg p-4 rounded-3 mb-4"
      style={{ position: "relative" }}
    >
      <div className="row gx-0 gy-xl-0 gy-7">
        <div className="col-xl-5 col-xxl-4">
          <div className="top_matches__clubname">
            <div className="top_matches__cmncard-right d-flex align-items-start justify-content-between pb-4 mb-4 gap-4 ">
              <div className="d-flex align-items-center gap-1">
                <img src={Football} alt="Icon" />{" "}
                <span className="fs-eight cpoint">Turkiye . Super Lig</span>
              </div>
              <div className="d-flex align-items-center gap-4 pe-xl-15 flex-nowrap flex-xl-wrap">
                <span className="fs-eight cpoint">Today, 23:00</span>
                <div className="d-flex align-items-center gap-1">
                  <img src={Updown} alt="Icon" />
                  <img src={Shirt} alt="Icon" />
                </div>
              </div>
            </div>
            <div className="top_matches__cmncard-left d-flex align-items-center justify-content-between pe-xl-10">
              <div>
                <div className="d-flex align-items-center gap-2 mb-4">
                  <img src={Club1} alt="Icon" />{" "}
                  <span className="fs-seven cpoint">Sivasspor</span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img src={Club2} alt="Icon" />{" "}
                  <span className="fs-seven cpoint">Trabzonspor</span>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 position-relative pe-xl-15">
                <span className="v-line lg d-none d-xl-block"></span>
                <div className="d-flex flex-column gap-5">
                  <img className="cpoint" src={Chart} alt="Icon" />
                  <img className="cpoint" src={Star} alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-xxl-8">
          <div className="top_matches__clubdata">
            <div className="table-responsive">
              <table className="table mb-0 pb-0">
                <thead>
                  <tr className="text-center">
                    <th scope="col">
                      <span className="fs-eight">1x2</span>
                    </th>
                    <th scope="col">
                      <span className="fs-eight">Double chance</span>
                    </th>
                    <th scope="col">
                      <span className="fs-eight">Total</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pt-4">
                      <div className="top_matches__innercount d-flex align-items-center gap-2 ">
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven d-block mb-2">draw</span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven d-block mb-2">draw</span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven d-block mb-2">draw</span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                      </div>
                    </td>
                    <td className="pt-4">
                      <div className="top_matches__innercount d-flex align-items-center gap-2 ">
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven d-block mb-2">draw</span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven d-block mb-2">draw</span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven d-block mb-2">draw</span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                      </div>
                    </td>
                    <td className="pt-4">
                      <div className="top_matches__innercount d-flex align-items-center gap-2 ">
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven d-block mb-2">draw</span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven d-block mb-2">draw</span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven d-block mb-2">draw</span>
                          <span className="fw-bold d-block">3.45</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showModal && <BetModal onClose={() => setshowModal(false)} />}
    </div>
  );
};
