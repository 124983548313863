import { BasketballCard } from "../sport_card/BasketballCard";
import { CricketCard } from "../sport_card/CricketCard";
import { ECricketCard } from "../sport_card/ECricketCard";
import { FootballCard } from "../sport_card/FootballCard";
import { HockeyCard } from "../sport_card/HockeyCard";
import { NBACard } from "../sport_card/NBACard";
import { TennisCard } from "../sport_card/TennisCard";

import Upcoming from "../../assets/images/icon/clock-icon.png";

import Soccer from "../../assets/images/icon/soccer-icon.png";
import Tennis from "../../assets/images/icon/tennis.png";
import Basketball from "../../assets/images/icon/basketball.png";
import Cricket from "../../assets/images/icon/cricket.png";
import ECricket from "../../assets/images/icon/ecricket.png";
import IceHockey from "../../assets/images/icon/ice-hockey.png";
import NBA from "../../assets/images/icon/nba2k.png";
import Crypto from "../../assets/images/icon/crypto.png";

import { CryptoCard } from "../sport_card/CryptoCard";
import { useState } from "react";

export const UpcomingEvents = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (index: any) => {
    setActiveTabIndex(index);
  };

  function getSportIcon(sport: any) {
    switch (sport) {
      case "Crypto":
        return <img src={Crypto} alt="Icon" />;
      case "Soccer":
        return <img src={Soccer} alt="Icon" />;
      case "Tennis":
        return <img src={Tennis} alt="Icon" />;
      case "Basketball":
        return <img src={Basketball} alt="Icon" />;
      case "Cricket":
        return <img src={Cricket} alt="Icon" />;
      case "eCricket":
        return <img src={ECricket} alt="Icon" />;
      case "Ice Hockey":
        return <img src={IceHockey} alt="Icon" />;
      case "NBA 2K":
        return <img src={NBA} alt="Icon" />;
      default:
        return null;
    }
  }

  // Helper function to render tab content based on activeTabIndex
  function renderTabContent(activeTabIndex: any) {
    const tabContents = [
      <div className="tabitemee active">
        <CryptoCard text="Ethereum" />
        <CryptoCard text="Solana" />
        <CryptoCard text="Bitcoin" />
      </div>,
      <div className="tabitemee active">
        <FootballCard />
        <FootballCard />
        <FootballCard />
      </div>,
      <div className="tabitemee active">
        <TennisCard />
        <TennisCard />
        <TennisCard />
      </div>,
      <div className="tabitemee active">
        <BasketballCard />
        <BasketballCard />
        <BasketballCard />
        <BasketballCard />
      </div>,
      <div className="tabitemee active">
        <CricketCard />
        <CricketCard />
        <CricketCard />
        <CricketCard />
        <CricketCard />
      </div>,
      <div className="tabitemee active">
        <ECricketCard />
        <ECricketCard />
        <ECricketCard />
      </div>,
      <div className="tabitemee active">
        <HockeyCard />
        <HockeyCard />
      </div>,
      <div className="tabitemee active">
        <NBACard />
        <NBACard />
      </div>,
    ];

    return tabContents[activeTabIndex];
  }

  return (
    <section className="top_matches pb-8 pb-md-10">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 gx-0 gx-lg-4">
            <div className="top_matches__main">
              <div className="row w-100 mb-8 mb-md-10">
                <div className="col-12">
                  <div className="top_matches__title d-flex align-items-center gap-2 mb-4">
                    <img src={Upcoming} alt="Icon" />
                    <h3>Upcoming events</h3>
                  </div>
                  <div className="top_matches__content">
                    <div className="singletab">
                      <ul className="tablinks d-flex align-items-center gap-4 flex-wrap mb-5 mb-md-6">
                        {[
                          "Crypto",
                          "Soccer",
                          "Tennis",
                          "Basketball",
                          "Cricket",
                          "eCricket",
                          "Ice Hockey",
                          "NBA 2K",
                        ].map((sport, index) => (
                          <li className="nav-links" key={index}>
                            <button
                              className={`tablink clickable-active2 d-flex align-items-center gap-2 py-2 px-4 p3-bg rounded-17 ${
                                activeTabIndex === index ? "active" : ""
                              }`}
                              onClick={() => handleTabClick(index)}>
                              {/* Render icon and text for each tab */}
                              {getSportIcon(sport)} {sport}
                            </button>
                          </li>
                        ))}
                      </ul>

                      <div className="tabcontents">
                        {renderTabContent(activeTabIndex)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
