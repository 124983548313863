import { HeroCard } from "./HeroCard";

export const HeroSlider = () => {
  return (
    <section className="hero_area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 gx-0 gx-sm-4">
            <div className="hero_area__main">
              <div className="row w-100">
                <div className="col-12">
                  {/* <div className="live-playingc">
                     <div className="hero_area__topslider swiper-wrapper"> 
                    
                      <HeroCard />
                      <HeroCard />
                      <HeroCard />
                     </div> 
                  </div> */}

<div className="live-playingc">
      <div className="live-playing-content">
        <HeroCard />
        <HeroCard />
        <HeroCard />
      </div>
    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
