import { useState } from "react";
import { BetModal } from "../modals/BetModal";

interface Props {
  text: string;
}

export const CryptoCard: React.FC<Props> = ({ text }) => {
  const [showModal, setshowModal] = useState(false);

  return (
    <div
      className="top_matches__cmncard p2-bg p-4 rounded-3 mb-4"
      style={{ position: "relative" }}
    >
      <div className="row gx-0 gy-xl-0 gy-7">
        <div className="col-xl-6 col-xxl-5">
          <div className="top_matches__clubname">
            <div className="top_matches__cmncard-right d-flex align-items-start justify-content-between pb-4 mb-4 gap-4 ">
              <div className="d-flex align-items-center gap-1">
                <span className="fs-eight cpoint">{text}</span>
              </div>
            </div>
            <div className="top_matches__cmncard-left d-flex align-items-center justify-content-between pe-xl-10">
              <div>
                <div className="d-flex align-items-center gap-2">
                  <span className="fs-seven cpoint" style={{ fontSize: 25 }}>
                    $65,000
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 position-relative pe-xl-15">
                <div className="d-flex flex-column gap-1">
                  <span className="top_matches__cmncard-countcercle  rounded-17 fs-seven">
                    Tomorrow 9:00am
                  </span>
                  {/* <span className="top_matches__cmncard-countcercle  rounded-17 fs-seven text-center">
                    0
                  </span> */}
                </div>
                <span className="v-line lg"></span>
                <div className="d-flex flex-column gap-5"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-xxl-7 d-xl-flex">
          <div className="top_matches__clubdata top_matches__clubdatatwo">
            <div className="table-responsive">
              <table
                className="table mb-0 pb-0"
                // style={{ background: "green" }}
              >
                <thead>
                  <tr className="text-center">
                    <th scope="col">
                      <span className="fs-eight">Above</span>
                    </th>
                    <th scope="col">
                      <span className="fs-eight">Below</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="pt-4">
                      <div className="top_matches__innercount d-flex align-items-center gap-2 ">
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven text-center d-block mb-2">
                            1
                          </span>
                          <span className="fw-bold d-block">1.5</span>
                        </div>
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven text-center d-block mb-2">
                            2
                          </span>
                          <span className="fw-bold d-block">3.8</span>
                        </div>
                      </div>
                    </td>
                    <td className="pt-4">
                      <div className="top_matches__innercount d-flex align-items-center gap-2 ">
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven text-center d-block mb-2">
                            1
                          </span>
                          <span className="fw-bold d-block">1.5</span>
                        </div>
                        <div
                          className="top_matches__innercount-item clickable-active py-1 px-8 rounded-3 n11-bg"
                          onClick={() => setshowModal(true)}
                        >
                          <span className="fs-seven text-center d-block mb-2">
                            2
                          </span>
                          <span className="fw-bold d-block">3.8</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr className=" w-100 mt-8 d-none d-xl-block n4-color" />
        </div>
      </div>

      {showModal && (
        <BetModal
          team1="Above $3000"
          team2="Below $3000"
          onClose={() => setshowModal(false)}
        />
      )}
    </div>
  );
};
