import Logo from "../../assets/images/logo.svg";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { logoutUser } from "../../service/magic";

export const AltHeader = () => {
  const [authUserDetails, setAuthUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userDetails = localStorage.getItem("authuserDetails");

    if (userDetails) {
      setAuthUserDetails(JSON.parse(userDetails));
    }
  }, []);

  const handleLogOut = async () => {
    try {
      setLoading(true);
      await logoutUser();
      localStorage.removeItem("authuserDetails");
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <header className="header-section2 header-section">
        <a
          href="https://theumpire.io"
          style={{ position: "absolute", left: 15, top: 10 }}
        >
          <img className="logo" src={Logo} alt="Logo" height={80} width={80} />
        </a>
        <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbar-content"
          >
            <ul className="navbar-nav2fixed navbar-nav d-flex align-items-lg-center gap-4 gap-sm-5 py-2 py-lg-0 align-self-center p2-bg">
              <li className="dropdown show-dropdown">
                <a href="/sport/Crypto">Crypto betting</a>
              </li>
              <li className="dropdown show-dropdown">
                <Link to={"/wallet"}>My Wallet</Link>
              </li>
              <li className="dropdown show-dropdown">
                <a href={"https://theumpire.io"}>Back to site</a>
              </li>
              <li className="dropdown show-dropdown d-block d-sm-none">
                <div className="d-flex align-items-center flex-wrap gap-3">
                  <a href="/register" className="cmn-btn px-xxl-11">
                    Register
                  </a>
                  {/* <div
                    className="cmn-btn"
                    style={{
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    <WalletMultiButton className="wallet-adapter-button" />
                  </div> */}

                  {authUserDetails ? (
                    <div
                      onClick={() => handleLogOut()}
                      className="cmn-btn px-xxl-11"
                      style={{
                        background: "transparent",
                        border: "3px solid red",
                        color: "red",
                      }}
                    >
                      {loading ? "Logging you out..." : "Logout"}
                    </div>
                  ) : (
                    <Link
                      to="/login"
                      className="cmn-btn px-xxl-11"
                      style={{
                        background: "transparent",
                        border: "1px solid #4CA2BF",
                        color: "#4CA2BF",
                      }}
                    >
                      Login
                    </Link>
                  )}
                </div>
              </li>
            </ul>
          </div>
          <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-7 align-items-center me-5 me-xl-10">
            <a
              href="/register"
              className="cmn-btn d-none px-xxl-11 d-sm-block d-lg-none d-xl-block"
              id="small-disappear"
            >
              Register
            </a>

            {authUserDetails ? (
              <div
                onClick={() => handleLogOut()}
                className="cmn-btn d-none px-xxl-11 d-sm-block d-lg-none d-xl-block"
                style={{
                  background: "transparent",
                  border: "3px solid red",
                  color: "red",
                }}
              >
                {loading ? "Logging you out..." : "Logout"}
              </div>
            ) : (
              <Link
                to="/login"
                className="cmn-btn d-none px-xxl-11 d-sm-block d-lg-none d-xl-block"
                style={{
                  background: "transparent",
                  border: "1px solid #4CA2BF",
                  color: "#4CA2BF",
                }}
              >
                Login
              </Link>
            )}
          </div>
          <button
            className="navbar-toggler mt-1 mt-sm-2 mt-lg-0"
            type="button"
            data-bs-toggle="collapse"
            aria-label="Navbar Toggler"
            data-bs-target="#navbar-content"
            aria-expanded="true"
            id="nav-icon3"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </nav>
      </header>
    </>
  );
};
